import React from "react"
import overBrand from "../brand-grid/over-logo.svg";
import stern from "./stern.png";
import beatles from "./beatles.png";
import dead from "./dead.png";
import batman from "./batman.png";
import iron from "./iron.png";
import monster from "./monster.png";
import guardians from "./guardians.png";
import bulletRed from "./bullet-red.svg";
import bulletWhite from "./bullet-white.svg";
import flecheRed from "./fleche-red.svg";

export default class DistributorSlider extends React.Component {

    state = {
        currentSize: "desktop",
        size: {
            desktop: {width: 344, height: 380},
            mobile: {width: 344, height: 380},
        },
        elements: {
            games: [
                {
                    name: "Beatles",
                    url: "",
                    resource: beatles,
                    positions: {desktop: {x: 103, y: 57}, mobile: {x: 103, y: 57}}
                },
                {
                    name: "Deadpool",
                    url: "",
                    resource: dead,
                    positions: {desktop: {x: 103, y: 57}, mobile: {x: 103, y: 57}}
                },
                {
                    name: "Batman",
                    url: "",
                    resource: batman,
                    positions: {desktop: {x: 103, y: 57}, mobile: {x: 103, y: 57}}
                },
                {
                    name: "Iron Maiden",
                    url: "",
                    resource: iron,
                    positions: {desktop: {x: 103, y: 57}, mobile: {x: 103, y: 57}}
                },
                {
                    name: "Guardians of the Galaxy",
                    url: "",
                    resource: guardians,
                    positions: {desktop: {x: 103, y: 57}, mobile: {x: 103, y: 57}}
                },
                {
                    name: "The Munsters",
                    url: "",
                    resource: monster,
                    positions: {desktop: {x: 103, y: 57}, mobile: {x: 103, y: 57}}
                },
            ]
        },
        index: 0,
    };

    render() {
        let size = this.state.currentSize;
        let slider = {x: 5, y: 270};
        return (<div className="distributors">
            <svg
                preserveAspectRatio="xMidYMid meet"
                viewBox={"0 0 " + this.state.size[size].width + " " + this.state.size[size].height}
            >
                <g onClick={() => {window.open("https://sternpinball.com/", "_blank")}}>
                    <image xlinkHref={stern} x={0} y={0} height="177" width="155"/>
                    <image xlinkHref={overBrand} x={0} y={0} height="177" width="155" className="over"/>
                </g>
                {
                    this.state.elements.games.map((value, i) => <g key={value.name} className={i === this.state.index ? "visible":"hide"}>
                        <image xlinkHref={value.resource} x={value.positions[size].x} y={value.positions[size].y} height="273" width="240"/>
                    </g>)
                }
                <g id="navButton" style={{cursor: "pointer"}}>
                    <image onClick={() => this.setPosition(this.state.index - 1)} xlinkHref={flecheRed} x={-1 * slider.x + -15} y={slider.y} height="22" width="13" style={{transform: "scaleX(-1)"}}/>
                    {
                        this.state.elements.games.map((value, i) => <g
                            key={"button" + value.name}
                            onClick={() => this.setPosition(i)}
                        >
                            <image xlinkHref={this.state.index === i ? bulletRed : bulletWhite} x={slider.x + 20 + 15 * i} y={slider.y + 5} height="10" width="10"/>
                        </g>)
                    }
                    <image onClick={() => this.setPosition(this.state.index + 1)} xlinkHref={flecheRed} x={slider.x + 20 + 15 * this.state.elements.games.length} y={slider.y} height="22" width="13"/>
                </g>
            </svg>
        </div>)

    }

    setPosition = (position) => {
        clearInterval(this.timeout);
        if(position < 0) {
            position = this.state.elements.games.length - 1;
        }
        this.setState({index: Math.max(position, 0) % this.state.elements.games.length});
        this.timeout = setInterval(this.next, 4500);
    };

    updateDimensions = () => {
        let size = this.state.currentSize;
        if (size === "mobile" && window.matchMedia("(min-width: 920px)").matches) {
            this.setState({currentSize: "desktop"})
        } else if (size === "desktop" && window.matchMedia("(max-width: 920px)").matches) {
            this.setState({currentSize: "mobile"})
        }
    };

    next = () => {
        this.setState({index: (this.state.index + 1)%(this.state.elements.games.length)})
    };

    componentDidMount() {
        this.updateDimensions();
        this.timeout = setInterval(this.next, 4500);
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
        window.removeEventListener("resize", this.updateDimensions);
    }

}
